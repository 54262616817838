[data-v-d5cadf47] .delete {
  color: #ff4d4f;
}
.delete-icon[data-v-d5cadf47] {
  height: 13px;
}
.photo-item-container[data-v-d5cadf47] {
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
}
@media screen and (max-width: 640px) {
.photo-item-container[data-v-d5cadf47] {
    flex-direction: column;
}
}
@media screen and (min-width: 640px) {
.photo-item-container[data-v-d5cadf47] {
    flex-direction: row;
}
}
.photo-item-container[data-v-d5cadf47]:last-child {
  border-bottom: 0;
}
.photo-info[data-v-d5cadf47] {
  flex: 1;
  padding: 0px 20px;
}
.photo-info label[data-v-d5cadf47] {
  font-weight: bolder;
  margin-right: 8px;
  margin-bottom: 0 !important;
}
.photo-info > div[data-v-d5cadf47] {
  margin-bottom: 8px;
}
.photo-info > div[data-v-d5cadf47]:last-child {
  margin-bottom: 0px;
}
.photo-info .photo-Button[data-v-d5cadf47] {
  display: flex;
  margin-left: 8px;
}
.photo-info .photo-part-container[data-v-d5cadf47] {
  display: flex;
}
.photo-info .photo-part[data-v-d5cadf47] {
  flex: 1;
}
.photo-info .par-dev[data-v-d5cadf47] {
  border: 1px solid #d9d9d9;
}
.image[data-v-d5cadf47] {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-color: #f6f6f6;
}
.image > img[data-v-d5cadf47] {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0px;
  max-width: 100px;
  max-height: 100px;
  margin: auto;
}
.image.inset-shadow[data-v-d5cadf47]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 8px 4px inset;
  content: '';
}
.image .dropdown-container[data-v-d5cadf47] {
  position: absolute;
  top: 6px;
  right: 40px;
  display: block;
  background-color: #91d5ff;
}
.image .iconBall[data-v-d5cadf47] {
  position: absolute;
  width: 32px;
  height: 32px;
  color: white;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  background-color: #91d5ff;
  border-radius: 16px;
  opacity: 0;
  transform: rotate(-90deg);
}
.image[data-v-d5cadf47]:hover {
  background-color: #f0f6ff;
}
.image:hover .iconBall[data-v-d5cadf47] {
  opacity: 0.8;
}
.image:hover .iconBall[data-v-d5cadf47]:hover {
  background-color: #91d5ff;
  opacity: 1;
}
