@media screen and (max-width: 640px) {
.photo-info-container[data-v-da789f12] {
    flex-direction: column;
}
.photo-info-container .left[data-v-da789f12] {
    width: 100%;
    border-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
@media screen and (min-width: 640px) {
.photo-info-container[data-v-da789f12] {
    flex-direction: row;
}
.photo-info-container .left[data-v-da789f12] {
    width: 100px;
    border-right: 1px solid #eee;
}
}
.photo-info-container[data-v-da789f12] {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
}
.photo-info-container[data-v-da789f12]:last-child {
  border-bottom: 0;
}
.photo-info-container .photo-image-container[data-v-da789f12] {
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.photo-info-container .photo-image-container img[data-v-da789f12] {
  width: 100%;
}
.photo-info-container .photo-delete[data-v-da789f12] {
  margin-top: 8px;
}
.photo-info-container .right[data-v-da789f12] {
  flex: 1;
}
