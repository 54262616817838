[data-v-38e8ed3f] .el-checkbox {
  margin-bottom: 0px !important;
}
[data-v-38e8ed3f] .el-checkbox__original {
  width: 0px !important;
  height: 0px !important;
  border: 0px !important;
}
.progress-dialog[data-v-38e8ed3f] .el-dialog__body {
  height: 350px;
  overflow-y: scroll;
}
